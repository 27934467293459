.app {
  overflow: visible;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.app__body {
  width: 100%;
  height: calc(100vh - 110px);
  bottom: 0;
  top: 95px;
  left: 0;
  font-size: $font-size-medium;
}
