.more-info-links-dropdown {
  &__title {
    font-size: 20px;
    padding: 10px;
  }
  &__description {
    color: gray;
    padding: 0px 10px 10px 10px;
  }
}
