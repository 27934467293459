.new-feature-notification {
  border-bottom: 1px solid lightgrey;
  color: black;
  width: 100%;
  padding: 15px;
  &--read {
    background-color: #e6e6e6;
    color: gray;
  }
  &__menu-item {
    height: 100%;
    display: flex;
    > div > div {
      display: flex;
    }
  }
  &__icon {
    display: flex;
    align-items: start;
    height: 48px;
    padding-right: 16px;
    width: 10%;
  }
  &__heading {
    display: flex;
    justify-content: space-between;
  }
  &__container {
    width: 90%;
  }
  &__title {
    font-weight: bold;
  }
  &__date {
    color: gray;
    font-size: 12px;
    margin-left: auto;
    font-style: italic;
  }
  &__description {
    font-size: 14px;
    max-width: 400px;
    white-space: break-spaces;
    line-height: normal;
    text-align: justify;
    a {
      font-weight: bold;
      color: black;
    }
    ul {
      list-style-type: disc;
    }
  }
  &__image {
    box-shadow: -2px 2px 4px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 400px;
    border-radius: 2px;
    z-index: 9999;
  }
}
