@import '../../../../css/colorsGFW';

.new-feature-notifications {
  > div {
    /*  padding fix to avoid scroll overlap in firefox */
    padding-right: 5px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0px 16px;
    align-items: center;
  }
  &__mark-as-read {
    text-align: right;
    padding: 0px 16px;
    button {
      background: none;
      color: $gfw-green;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
  }
  &__unread-notifications {
    position: absolute;
    background: red;
    color: white;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    left: 6px;
  }
}
