@mixin account-info-label() {
  height: 35px;
  line-height: 35px;
}

@mixin account-info-item() {
  min-height: 70px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 10px 10px 10px;
}

// Typography mixins
@mixin antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin _type($settings, $size, $color: null, $weight: null, $inverted: false) {
  @each $property, $value in map-get($settings, $size) {
    #{$property}: $value;
  }

  @if $color {
    color: $color;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $inverted {
    color: #ffffff;
    @include antialiased();
  }
}

@mixin text($size: $default-font-size-text, $color: null, $weight: null, $inverted: false) {
  @if not map-has-key($text-settings, $size) {
    @error "#{$size} is not an allowable text size";
  }

  @include _type($text-settings, $size, $color, $weight, $inverted);
}

@mixin heading($size: $default-font-size-heading, $color: null, $weight: null, $inverted: false) {
  @if not map-has-key($heading-settings, $size) {
    @error "#{$size} is not an allowable heading size";
  }

  @include _type($heading-settings, $size, $color, $weight, $inverted);
}

// Focus outline mixin
@mixin focus-outline() {
  outline: 0;
  box-shadow: 0 0 0 2px rgba($blue, 0.25);
}
