.inner {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

@media (min-width: $mobileBreak) {
  .inner {
    max-width: 1024px;
    width: 95%;
  }
}

@media (min-width: 1400px) {
  .inner {
    max-width: 1200px;
    width: 75%;
  }
}
